import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { useModalContext } from '@contexts/modalContext';
import { useScreenSize } from '@hooks/useScreenSize';

import CustomersSection from '@components/molecules/customers-section';
import HeroSection from '@components/molecules/hero-section';
import CustomersPains from '@components/molecules/customers-pains';
import RetailSection from '@components/molecules/retail-section';
import EconomySimulator from '@components/molecules/economy-simulator';
import VideoSection from '@components/molecules/video-section';
import CompetitiveAdvantages from '@components/molecules/competitive-advantages';
import BannerSection from '@components/molecules/banner-section';
import FAQSection from '@components/molecules/faq-section';
import NewsletterSection from '@components/molecules/newsletter-section';
import ButtonComponent from '@components/atoms/button/button';
import MetaTags from '@components/atoms/meta-tags';

import style from '@pages/home-page/style.module.css';

import heroImage from '@assets/background-raio.png';
import heroImage1 from '@assets/hero-section-0.png';
import heroPhone from '@assets/solution-00.png';
import thumbnailVideoCase from '@assets/thumbnail-video-case.png';

import videoCase from '@assets/video/apresentacaoCaseMigracao.mp4';
import captionCase from '@assets/video/captionApresentacaoCaseMigracao.vtt';

function HomePage() {
  const isSmallScreen = useScreenSize();
  const { isModalOpen, setModalOpen } = useModalContext();

  const handleLearnMoreClick = () => {
    window.open('/sobre-nos', '_blank');
  };

  const heroBannerSection = twMerge(
    isSmallScreen ? 'min-w-fit py-8 mx-auto' : 'pt-[6.44rem] pb-[6.56rem] pl-20',
    style.HeroBannerContent,
  );
  const ctaButton = twMerge(isSmallScreen ? 'w-full text-left flex' : 'w-fit text-center', style.ctaButton);
  const heroBannerTitle = twMerge(
    isSmallScreen
      ? 'text-center  text-heading-xxLarge leading-[2.8rem] -mt-4 px-4'
      : 'text-left w-[34.19rem] text-display-small leading-[4.5rem]',
    style.HeroBannerTitle,
  );
  const heroBannerParagraph = twMerge(
    isSmallScreen ? 'mb-2 text-paragraph-medium text-center px-4' : 'mt-4 mb-[1.69rem] text-heading-xSmall text-left',
    style.HeroBannerParagraph,
  );
  const bannerStyle = twMerge(isSmallScreen ? 'hidden' : 'block', style.BannerStyle);

  const openModal = () => {
    if (!isModalOpen) {
      setModalOpen(true);
    }
  };

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isModalOpen]);

  return (
    <section aria-label="Página Inicial">
      <MetaTags
        title="Economize até 40% na conta de luz da sua empresa"
        description="A Clarke ajuda a sua empresa a economizar na conta de luz com o Mercado Livre de Energia. Compre energia limpa e mais barata com a gente!"
        canonicalLink="https://clarke.com.br/"
      />
      <HeroSection
        imgPath={heroImage}
        imgHeight={706}
        imgWidth={779}
        imgAlt="Prévia do sistema da Clarke num smartphone com fundo de raio verde"
        aria-hidden="true"
        imgContent={heroPhone}
        imgContentClasses="relative mr-[9rem] rotate-[10deg]"
        imgExtraClasses={bannerStyle}
      >
        <div className={heroBannerSection}>
          <h1 className={heroBannerTitle}>
            Compre energia até <span className="text-brand-primary-0">40% mais barata</span> para sua empresa
          </h1>
          <p className={heroBannerParagraph}>Sem investimentos e sem dor de cabeça!</p>
          <img
            src={heroImage1}
            alt="Prévia do sistema da Clarke num smartphone com fundo de raio verde"
            className={twMerge(isSmallScreen ? 'block' : 'hidden', 'mx-auto w-[25.875rem] h-[20.875rem] object-cover')}
          />
          <div className={isSmallScreen ? 'px-4' : undefined}>
            <ButtonComponent
              icon="ArrowRightIcon"
              iconPosition="right"
              iconType="solid"
              iconStyles="w-10 h-10 bg-brand-light-20 text-brand-primary-70"
              kind="primaryCustom"
              size="1"
              label="Quero fazer uma simulação"
              className={twMerge(isSmallScreen && '-mt-[0.1875rem] justify-between', ctaButton)}
              onClick={openModal}
            />
          </div>
        </div>
      </HeroSection>
      <CustomersSection />
      <CustomersPains />
      <RetailSection />
      <EconomySimulator />
      <VideoSection
        videoPath={videoCase}
        videoTitle="Mais de 30% de economia! Cliente Clarke expandiu sua fábrica após migrar para Mercado Livre"
        title="A Clarke acredita na democratização da energia"
        description="A primeira gestora independente do Mercado Livre de Energia no Brasil a trazer economia, transparência, segurança e previsibilidade no consumo de energia da sua empresa."
        descriptionMaxWidth="max-w-full"
        thumbnailPath={thumbnailVideoCase}
        captionPath={captionCase}
        uploadDate="2024-08-23"
        duration="PT4M00S"
      >
        <ButtonComponent
          icon="ArrowRightIcon"
          iconPosition="right"
          iconType="solid"
          iconStyles="text-brand-gray-80 w-5 h-5"
          kind="secondary"
          size="1"
          label="Saiba mais sobre nós"
          className={twMerge(ctaButton, 'mt-6 h-[2.8125rem]')}
          onClick={handleLearnMoreClick}
        />
      </VideoSection>
      <CompetitiveAdvantages />
      <BannerSection />
      <FAQSection />
      <NewsletterSection />
    </section>
  );
}

export default HomePage;
