import logoAlfredo from '@assets/logo01.svg';
import logoBotafogo from '@assets/logo02.svg';
import logoCSN from '@assets/logo03.svg';
import logoMarcel from '@assets/logo04.svg';
import logoBurguerKing from '@assets/logo05.svg';
import logoMobly from '@assets/logo06.svg';
import logoMapleBearCanadianSchool from '@assets/logo07.svg';
import logoMSGStones from '@assets/logo08.svg';
import logoDrConsulta from '@assets/logo09.svg';
import logoFlamengo from '@assets/logo10.svg';
import logoLeCordonBlueu from '@assets/logo11.svg';

import timeline1 from '@assets/timeline-1.png';
import timeline2 from '@assets/timeline-2.png';
import timeline3 from '@assets/timeline-3.png';
import timeline4 from '@assets/timeline-4.png';
import timeline5 from '@assets/timeline-5.png';
import timeline6 from '@assets/timeline-6.png';

export const logosCarousel = [
  [
    { src: logoLeCordonBlueu, alt: 'Logotipo da escola Le Cordon Bleu' },
    { src: logoFlamengo, alt: 'Logotipo do Clube de Regatas do Flamengo' },
    { src: logoDrConsulta, alt: 'Logotipo da empresa Dr. Consulta' },
    { src: logoMSGStones, alt: 'Logotipo da empresa MSG Stones' },
    { src: logoMapleBearCanadianSchool, alt: 'Logotipo da Maple Bear Canadian School' },
    { src: logoMobly, alt: 'Logotipo da empresa Mobly' },
    { src: logoBurguerKing, alt: 'Logotipo da empresa Burger King' },
    { src: logoMarcel, alt: 'Logotipo da empresa Marcel Group' },
    { src: logoCSN, alt: 'Logotipo da Companhia Siderúrgica Nacional' },
    { src: logoBotafogo, alt: 'Logotipo da empresa Botafogo' },
    { src: logoAlfredo, alt: 'Logotipo da empresa Alfredo' },
  ],
];

interface CardEnergyInsightCard {
  title: string;
  description: string;
  icon: string;
}

export const CardEnergyInsightsData: CardEnergyInsightCard[] = [
  {
    title: 'Monitoramento de consumo',
    description: 'Nosso time de especialistas estará sempre conectada com os dados de consumo da sua empresa.',
    icon: 'EyeIcon',
  },
  {
    title: 'Relatório',
    description:
      'Mensalmente, nossos clientes têm acesso a relatórios de economia automáticos para entender seus resultados.',
    icon: 'DocumentChartBarIcon',
  },
  {
    title: 'Recontratação de energia',
    description:
      'Buscando sempre o menor preço, nosso time é proativo para te orientar sobre o momento certo de contratar mais energia.',
    icon: 'LightBulbIcon',
  },
];

interface ServiceCard {
  title: string;
  description: string;
  iconName: string;
  iconType: 'solid' | 'outline';
}

export const firstServicesCardSolutions: ServiceCard[] = [
  {
    title: 'Migração',
    description:
      'Oferecemos representação e assessoria durante todo o processo de migração para o mercado livre, cuidando das rotinas e dos contratos junto à distribuidora, ao fornecedor de energia e aos órgãos competentes.',
    iconName: 'ArrowRightStartOnRectangleIcon',
    iconType: 'solid',
  },
  {
    title: 'Contratação',
    description:
      'Consultoria na definição do melhor contrato de energia e acesso à tecnologia da Clarke para garantir o melhor contrato de energia possível no mercado brasileiro.',
    iconName: 'DocumentDuplicateIcon',
    iconType: 'outline',
  },
  {
    title: 'Gestão',
    description:
      'Consultoria, representação e auditoria mensal no mercado livre de energia. O cliente tem a comodidade de ter um parceiro responsável pelo seu relacionamento com a distribuidora, fornecedor de energia e órgãos competentes - garantindo boas contratações futuras e auxílio em projetos de expansão e adequação.',
    iconName: 'UserPlusIcon',
    iconType: 'outline',
  },
];

export const secondServicesCardSolutions: ServiceCard[] = [
  {
    title: 'Tecnologia',
    description:
      'Utilizamos da tecnologia com foco em entregar melhores resultados e experiência para nossos clientes em nossos três pilares de atuação. Com a Clarke, todas as etapas são acompanhadas por você de forma digital, garantindo transparência e agilidade na tomada de decisão.',
    iconName: 'CodeBracketSquareIcon',
    iconType: 'outline',
  },
  {
    title: 'Atendimento',
    description:
      'Atendimento consultivo e especializado 100% dedicado, aliado a experiência simples e completa de nossas plataformas.',
    iconName: 'UsersIcon',
    iconType: 'outline',
  },
  {
    title: 'Segurança',
    description:
      'Tenha visibilidade do risco de cada fornecedor e tome decisões seguras com a assessoria da  gestão independente realizada pela Clarke.',
    iconName: 'ShieldCheckIcon',
    iconType: 'outline',
  },
];

interface ContentOfLinks {
  name: string;
  src: string;
  typeOfWindow: string;
}

export const LinksContent: ContentOfLinks[] = [
  {
    name: 'TalkToEnergySpecialist',
    src: 'https://wa.me/15557005576?text=Oi!%20Vim%20atrav%C3%A9s%20do%20site%20da%20Clarke%20Energia%20e%20quero%20falar%20com%20um%20especialista.',
    typeOfWindow: '_blank',
  },
  {
    name: 'ScheduleDemo',
    src: 'https://meetings.hubspot.com/giselle-ramos',
    typeOfWindow: '_blank',
  },
  {
    name: 'WantToKnowMore',
    src: 'https://blog.clarke.com.br/post/governo-abre-mercado-livre-de-energia-para-a-alta-tensao-a-partir-de-2024/',
    typeOfWindow: '_blank',
  },
  {
    name: 'ClarkeVarejo',
    src: 'https://wa.me/15557005576?text=Ol%C3%A1!%20Pode%20me%20explicar%20melhor%20sobre%20Clarke%20Varejo%3F%20A%20velocidade%20e%20facilidade%20oferecidas%20me%20chamaram%20a%20aten%C3%A7%C3%A3o.',
    typeOfWindow: '_blank',
  },
  {
    name: 'ClarkeGestao',
    src: 'https://wa.me/15557005576?text=Ol%C3%A1!%20Pode%20me%20explicar%20melhor%20sobre%20o%20Clarke%20Gest%C3%A3o%3F%20Tenho%20interesse%20em%20uma%20solu%C3%A7%C3%A3o%20personalizada.',
    typeOfWindow: '_blank',
  },
  {
    name: 'InitiativeLiterOfLight',
    src: 'https://blog.clarke.com.br/post/mais-nova-b-corp-brasileira-clarke-chega-a-marca-de-100-clientes-no-mercado-livre-de-energia/',
    typeOfWindow: '_blank',
  },
  {
    name: 'WhatIsMarketEnergy',
    src: '/mercado-livre-de-energia/',
    typeOfWindow: '_blank',
  },
];

export const ourHistoryTimelineContent = [
  {
    '@type': 'Event',
    name: '2019',
    image: {
      '@type': 'ImageObject',
      url: timeline1,
      width: 161,
      height: 166,
      caption: 'Foto dos dois fundadores da Clarke segurando um tablet com a ilustração do Marketplace.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li>
          A Clarke, que tem o Victor e o Pedro como dois de seus fundadores, nasceu com a visão de se tornar um{' '}
          <span className="font-bold">negócio lucrativo enquanto gera impacto positivo no mundo.</span>
        </li>
      </ul>
    ),
  },
  {
    '@type': 'Event',
    name: '2020',
    image: {
      '@type': 'ImageObject',
      url: timeline2,
      width: 161,
      height: 166,
      caption: 'Foto de um dos fundadores da Clarke ao lado de um painel solar gigante.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li>
          A <span className="font-bold">Clarke Energia</span> desenvolveu uma série de soluções até encontrar a melhor
          opção para nossos clientes: <span className="font-bold">o mercado livre de energia.</span>
        </li>
      </ul>
    ),
  },
  {
    '@type': 'Event',
    name: '2021',
    image: {
      '@type': 'ImageObject',
      url: timeline3,
      width: 161,
      height: 166,
      caption: 'Foto de um dos fundadores da Clarke ao lado do integrante do Grupo Nova Aurora.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li>
          Em 2021 a Clarke se tornou o primeiro marketplace de mercado livre de energia do Brasil. A Nova Aurora então
          tornou-se nossa primeira cliente.
        </li>
      </ul>
    ),
  },
  {
    '@type': 'Event',
    name: '2022',
    image: {
      '@type': 'ImageObject',
      url: timeline4,
      width: 161,
      height: 166,
      caption: 'Foto dos membros da Clarke durante o evento de expansão regulatória do setor.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li>
          A startup também apoiou a expansão regulatória do setor com{' '}
          <span className="font-bold">agendas no congresso</span> e com o{' '}
          <span className="font-bold">ministério de minas e energia.</span>
        </li>
      </ul>
    ),
  },
  {
    '@type': 'Event',
    name: '2023',
    image: {
      '@type': 'ImageObject',
      url: timeline5,
      width: 161,
      height: 166,
      caption: 'Foto de duas mulheres segurando a premiação de melhor startup de energia.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li className="list-none -ml-[1.125rem]"> A Clarke Energia se tornou referência.</li>
        <li>
          <span className="font-bold">Top 10 carteiras de clientes</span> do varejo do Brasil.
        </li>
        <li>
          <span className="font-bold">Maior empresa de tecnologia</span> do setor. Selecionada em programas do Google,
          Amazon e Microsoft.
        </li>
        <li>
          <span className="font-bold">3x premiada uma das melhores startups de energia</span> do Brasil pelo prêmio 100
          open startups.
        </li>
      </ul>
    ),
  },
  {
    '@type': 'Event',
    name: '2024',
    image: {
      '@type': 'ImageObject',
      url: timeline6,
      width: 161,
      height: 166,
      caption: 'Foto dos fundadores da Clarke e da Energisa.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li>
          A Energisa se tornou sócia da Clarke Energia. Com isonomia e autonomia, estamos criando a gestora independente
          mais tech do Brasil.
        </li>
      </ul>
    ),
  },
];


export const LinksFooter: ContentOfLinks[] = [
  {
    name: 'Linkedin',
    src: 'https://www.linkedin.com/company/clarkeenergia/',
    typeOfWindow: '_blank',
  },
  {
    name: 'Facebook',
    src: 'https://www.facebook.com/clarkeenergia',
    typeOfWindow: '_blank',
  },
  {
    name: 'Instagram',
    src: 'https://www.instagram.com/clarkeenergia/',
    typeOfWindow: '_blank',
  },
  {
    name: 'Youtube',
    src: 'https://www.youtube.com/@clarkeenergia',
    typeOfWindow: '_blank',
  },
  {
    name: 'Vacancies',
    src: 'https://clarke.gupy.io/',
    typeOfWindow: '_blank',
  },
  {
    name: 'Address',
    src: 'https://www.google.com/maps/search/?api=1&query=Clarke+Energia',
    typeOfWindow: '_blank',
  },
];

export const faqData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "O que é a Clarke Energia?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A Clarke Energia é uma gestora independente que assessora clientes no Mercado Livre de Energia desde o processo de contação e contratação, passando pela migração até o acompanhamento personalizado com foco na eficiência energética. A Clarke é a maior empresa de tecnologia do setor, reconhecida por práticas de sustentabilidade e ESG."
      }
    },
    {
      "@type": "Question",
      "name": "Quais são os benefícios da Clarke Energia?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "O mercado livre de energia oferece economia e sustentabilidade. Com o suporte da Clarke, sua empresa tem segurança para contratar energia dos melhores fornecedores de energia do Brasil somado com a tecnologia e o atendimento da empresa de tecnologia mais premiada do mercado livre de energia."
      }
    },
    {
      "@type": "Question",
      "name": "Como a tecnologia da Clarke vai ajudar minha empresa?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Nossas plataformas garantem ao cliente o melhor contrato de energia que ele pode encontrar no momento da sua busca e a melhor experiência para acompanhar seus resultados mensais. São elas:"
      }
    },
    {
      "@type": "Question",
      "name": "Como posso contratar os serviços da Clarke Energia?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Você pode contratar os serviços da Clarke Energia entrando em contato com um consultor especialista e fazendo uma simulação de economia detalhada através dos dados existentes na conta de luz da sua empresa; "
      }
    },
    {
      "@type": "Question",
      "name": "Eu posso ser cliente da Clarke Energia?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Você pode contratar os serviços da Clarke Energia entrando em contato com um consultor especialista e fazendo uma simulação de economia detalhada através dos dados existentes na conta de luz da sua empresa;"
      }
    },
    {
      "@type": "Question",
      "name": "Como a Clarke pode me ajudar se eu já possuo placas solares?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Se empresa possua placas solares e faz parte do sistema de Geração Distribuída de Energia, a Clarke poderá te ajudar caso a geração da energia não seja suficiente para abater todo o consumo da sua unidade. Nossos especialistas irão analisar qual o melhor contrato para comprar o residual da energia de forma mais barata e ainda garantir algum desconto para sua Demanda Contratada."
      }
    },
    {
      "@type": "Question",
      "name": "A Clarke faz parte do Grupo Energisa?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Em março de 2024, o Grupo Energisa realizou investimento na Clarke Energia, tornando-se sócia. As marcas compartilham a vanguarda de um novo mercado e consumo de energia no Brasil, unindo a tecnologia com a segurança e credibilidade de uma companhia que atende mais 20 milhões de clientes em 829 municípios há mais de 119 anos. Parte do Grupo Energisa, a Clarke mantém sua autonomia tendo sua operação 100% separada do seu investidor."
      }
    }
  ]
};

export const contactData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Clarke Energia",
  "url": "https://www.clarke.com.br",
  "logo": "https://clarke.com.br/assets/logo-clarke-energia-B3vZElX6.svg",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+55 (11) 93409-0834",
    "contactType": "customer service",
    "areaServed": "BR",
    "availableLanguage": ["Portuguese"]
  },
  "location": {
    "@type": "Place",
    "name": "Clarke Energia Sede",
    "address": "Av. Paulista, 1374 - Bela Vista, São Paulo - SP, CEP 01310-100, Brasil"
  }
};

export const timelineData = {
  "event": [
    {
      "@type": "Event",
      "name": "Fundação da Clarke",
      "startDate": "2019-01-01",
      "description": "A Clarke, que tem o Victor e o Pedro como dois de seus fundadores, nasceu com a visão de se tornar um negócio lucrativo enquanto gera impacto positivo no mundo.",
      "image": "https://clarke.com.br/assets/timeline-1-DHM_X0ks.png"
    },
    {
      "@type": "Event",
      "name": "Desenvolvimento de soluções",
      "startDate": "2020-01-01",
      "description": "A Clarke Energia desenvolveu uma série de soluções até encontrar a melhor opção para nossos clientes: o mercado livre de energia.",
      "image": "https://clarke.com.br/assets/timeline-2-BTR-SQFL.png"
    },
    {
      "@type": "Event",
      "name": "Lançamento do marketplace",
      "startDate": "2021-01-01",
      "description": "Em 2021 a Clarke se tornou o primeiro marketplace de mercado livre de energia do Brasil. A Nova Aurora então tornou-se nossa primeira cliente.",
      "image": "https://clarke.com.br/assets/timeline-3-DJmZfiMe.png"
    },
    {
      "@type": "Event",
      "name": "Apoio à expansão regulatória",
      "startDate": "2022-01-01",
      "description": "A startup também apoiou a expansão regulatória do setor com agendas no congresso e com o ministério de minas e energia.",
      "image": "https://clarke.com.br/assets/timeline-4-CZ_MTkXo.png"
    },
    {
      "@type": "Event",
      "name": "Reconhecimento e premiações",
      "startDate": "2023-01-01",
      "description": "A Clarke Energia se tornou referência. Top 10 carteiras de clientes do varejo do Brasil. Maior empresa de tecnologia do setor. Selecionada em programas do Google, Amazon e Microsoft.",
      "image": "https://clarke.com.br/assets/timeline-5-HHIbFgoW.png"
    },
    {
      "@type": "Event",
      "name": "Parceria com a Energisa",
      "startDate": "2024-01-01",
      "description": "A Energisa se tornou sócia da Clarke Energia. Com isonomia e autonomia, estamos criando a gestora independente mais tech do Brasil.",
      "image": "https://clarke.com.br/assets/timeline-6-BIHnN0N1.png"
    }
  ]
}
